import React from "react"
import { Link } from "gatsby"

import Header from '../components/Header';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

const NotFoundPage = () => (
  <React.Fragment>
    <Header/>
    <Layout>
      <div className="info">
        <div className="info--title">
          404
        </div>
        <div className="info--text">
          The page could not be found.<br/>
          <Link to="/">Go back</Link>
        </div>
      </div>
    </Layout>
    <Footer/>
  </React.Fragment>
);

export default NotFoundPage;